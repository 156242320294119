<template>
	<div v-if="authed">
		<v-data-table :headers="headers" :items="items" item-key="_id"
			:loading="loading" loading-text="加载中...">
			<template v-slot:top>
				<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:12px;">
					<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate1" label="操作日期起" readonly v-bind="attrs"
								v-on="on" dense hide-details style="flex: 0 1 auto;max-width:120px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate2" label="操作日期止" readonly v-bind="attrs"
								v-on="on" dense hide-details style="flex: 0 1 auto;max-width:120px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-select label="操作类型" hide-details :items="['预约','取消预约']" v-model="action" dense @change="fetchData" style="flex: 0 1 auto;max-width:130px;"/>
					<v-select label="业务类型" hide-details :items="colorsptz" item-value="tag" v-model="tag" dense @change="fetchData" style="flex: 0 1 auto;max-width:130px;"/>
					<v-select label="状态" hide-details :items="['全部','仅非常规']" v-model="abstate" dense @change="fetchData" style="flex: 0 1 auto;max-width:130px;"/>
					<v-btn color="primary" @click.stop="exportXLS">导出</v-btn>
				</div>
				<v-divider/>
			</template>
			<template v-slot:item.deadline="{ item }">
				{{formatTime(item.deadline)}}
			</template>
			<template v-slot:item.time="{ item }">
				{{formatTime(item.time)}}
			</template>
			<template v-slot:item.abstate="{ item }">
				<span v-if="item.time > item.deadline" class="red--text font-weight-bold">非常规</span>
			</template>
			<template v-slot:item.operative="{ item }">
				{{action + tagtext}}
			</template>
		</v-data-table>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import XLSX from 'xlsx-js-style'
	import {formatTime, formatDate, nowOffsetHours} from '../utils'
	import {colorsptz} from '../workset'

    export default {
        data() {
            return {
                authed: false,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'客户姓名', value:'pii.username', width:120},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'预约日期', value:'apntDate', width:120},
                    {text:'常规截止时间', value:'deadline', width:180},
                    {text:'实际操作时间', value:'time', width:180},
                    {text:'状态', value:'abstate', width:100},
                    {text:'操作者', value:'operator', width:120},
                    {text:'操作', value:'operative', width:120},
                    {text:'订单号', value:'orderid', width:120},
                ],
                items: [],
				loading: false,
				action: '预约',
				colorsptz,
				tag: 't',
				abstate: '仅非常规',
            }
        },
		computed: {
			tagtext() {
				return colorsptz.find(x => x.tag === this.tag).text;
			},
		},
		created() {
			this.formatTime = formatTime;
			this.pdate1 = formatDate(Date.now() + nowOffsetHours * 3600 * 1000 - 30 * 86400 * 1000);
			this.pdate2 = formatDate(Date.now() + nowOffsetHours * 3600 * 1000);
		},
		mounted() {
			this.authed = this.$hasPrivilege(['统计']);
		},
        methods: {
			async fetchData() {
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'getStatApnt',
							data: {
								timeBegin: this.pdate1,
								timeEnd: this.pdate2,
								tag: this.tag,
								title: this.action,
								abstate: this.abstate,
							}
						}
					});
					this.items = res.result;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async exportXLS() {
				if (this.items.length === 0) return;
				const header = [[
					'病案号',
					'客户姓名',
					'健康顾问',
					'预约日期',
					'常规截止时间',
					'实际操作时间',
					'状态',
					'操作者',
					'操作',
					'订单号',
				]];
				const oper = this.action + this.tagtext;
				const data = this.items.map(x => [
					x.pid,
					x.pii.username,
					x.consultantName,
					x.apntDate,
					formatTime(x.deadline),
					formatTime(x.time),
					x.time > x.deadline ? '非常规' : '',
					x.operator,
					oper,
					x.orderid,
				]);
				const t = '预约统计';
				const filename = t + ".xlsx";
				const ws_name = t;
				const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(header.concat(data));
				const bs = {
					style: "thin",
					color: "000000"
				};
				for(let i in ws) {
					if (typeof(ws[i]) != "object") continue;
					ws[i].s = {
						border: {
							top: bs, left: bs, right: bs, bottom:bs
						}
					}
				}
				XLSX.utils.book_append_sheet(wb, ws, ws_name);
				XLSX.writeFile(wb, filename);
			},
		},
		watch: {
			pdate1(newv, oldv) {
				if (newv && this.pdate2) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate2 = newv;
					}
				}
				this.fetchData();
			},
			pdate2(newv, oldv) {
				if (newv && this.pdate1) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate1 = newv;
					}
				}
				this.fetchData();
			},
		},
    }
</script>
